import config from "../../config/config";
import { isApp } from "../../helpers/appHelper";

const redirectUrl = (token: string) => {
  return String(config.baseUrl) + String(config.vkRedirectUrl) + token;
};

const vkAuthUrl = String(config.vkAuthUrl);
const url = (redirect: string) => {
  return (
    vkAuthUrl +
    "?client_id=" +
    config.vkApiId +
    "&display=popup&redirect_uri=" +
    redirect +
    "&response_type=code"
  );
};
interface popupOptions {
  width: number;
  height: number;
  url: string;
}

function popup(options: popupOptions): Window | null {
  const screenX =
      typeof window.screenX != "undefined" ? window.screenX : window.screenLeft,
    screenY =
      typeof window.screenY != "undefined" ? window.screenY : window.screenTop,
    outerWidth =
      typeof window.outerWidth != "undefined"
        ? window.outerWidth
        : window.document.body.clientWidth,
    outerHeight =
      typeof window.outerHeight != "undefined"
        ? window.outerHeight
        : window.document.body.clientHeight - 22,
    width = options.width,
    height = options.height,
    left = parseInt(String(screenX + (outerWidth - width) / 2), 10),
    top = parseInt(String(screenY + (outerHeight - height) / 2.5), 10),
    features =
      "width=" + width + ",height=" + height + ",left=" + left + ",top=" + top;
  return window.open(options.url, "blank", features);
}

const checkState = (active: Window, handleAfterAuth: CallableFunction) => {
  const timer = setInterval(function () {
    if (active.closed) {
      handleAfterAuth();
      clearInterval(timer);
    }
  }, 2000);
};

export const vkAuthService = (
  handleAfterAuth: CallableFunction,
  token: string,
) => {
  const active = popup({
    width: 665,
    height: 370,
    url: url(redirectUrl(token)),
  });

  if (active) {
    isApp() ? handleAfterAuth() : checkState(active, handleAfterAuth);
  }
};
