export default {
  isProd: process.env.REACT_APP_IS_PROD,
  isApp: process.env.REACT_APP_IS_APP,
  appVersion: Number(process.env.REACT_APP_APP_VERSION),
  baseUrl: process.env.REACT_APP_REQUEST_BASE_URL,
  vkApiId: process.env.REACT_APP_VK_API_ID,
  vkRedirectUrl: process.env.REACT_APP_VK_REDIRECT_URL,
  vkAuthUrl: process.env.REACT_APP_VK_AUTH_URL,
  socialVkUrl: process.env.REACT_APP_SOCIAL_VK_UPL,
  socialTelegramUrl: process.env.REACT_APP_SOCIAL_TELEGRAM_UPL,
  glitchTipDns: process.env.REACT_APP_GLITCHTIP_DNS,
  ruStoreUrl: String(process.env.REACT_APP_RU_STORE_URL),
  //
  soundUrl: process.env.REACT_APP_SOUND_URL,
  //
  pusherAppKey: process.env.REACT_APP_PUSHER_APP_KEY,
  pusherAppCluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  broadcastChatSupportChannel:
    process.env.REACT_APP_BROADCAST_CHAT_SUPPORT_CHANNEL,
  pusherHost: process.env.REACT_APP_PUSHER_HOST,
  pusherPort: process.env.REACT_APP_PUSHER_PORT,
  pusherAuthRout: process.env.REACT_APP_PUSHER_AUTH_ROUT,
};
