import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import wordListItem from "../../../models/wordListItem";
import PaginationComponent from "../../pagination/PaginationComponent";
import { countPage, elementsForPage } from "../../../helpers/paginateHelper";

interface wordListPropsInterface {
  items: wordListItem[];
  setItems: CallableFunction;
  disabled: boolean;
}

export default function WordsListComponent(props: wordListPropsInterface) {
  const elementsOnPage = 100;
  const [page, setPage] = useState(1);
  const removeWord = (index: number) => {
    const newItems = Array.from(props.items);

    props.setItems(
      newItems.filter((wordListItem: wordListItem, key: number) => {
        return key !== calculateIndex(index);
      }),
    );
  };

  function removeAll() {
    props.setItems([]);
  }

  const tableCellStyle = (index: number) => {
    return props.items[calculateIndex(index)].remove
      ? {
          textDecoration: "line-through",
          color: "#9c9c9c",
          backgroundColor: "#f2f2f2",
        }
      : {};
  };

  const calculateIndex = (index: number) => {
    return (page - 1) * 10 + index;
  };

  function OnlyTable() {
    return (
      <>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={"120px"}>
                <Button
                  onClick={() => {
                    removeAll();
                  }}
                >
                  {"Очистить"}
                </Button>
              </TableCell>
              <TableCell width={"30px"}>{"№"}</TableCell>
              <TableCell align="left">{"Слово"}</TableCell>
              <TableCell align="left">{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {elementsForPage({
              array: props.items,
              pageSize: elementsOnPage,
              page: page,
            }).map((item: wordListItem, index: number) => (
              <TableRow key={index} sx={tableCellStyle(index)}>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      removeWord(index);
                    }}
                  >
                    {item.remove ? "Вернуть" : "Убрать"}
                  </Button>
                </TableCell>
                <TableCell>{calculateIndex(index) + 1}</TableCell>
                <TableCell align="left">{item.learningWord.word}</TableCell>
                <TableCell align="center">
                  {item.learningWord.public === 1 ? (
                    ""
                  ) : (
                    <Tooltip
                      title="Слово станет доступным после подтверждения администратором"
                      placement="top-end"
                      enterTouchDelay={0}
                    >
                      <WarningAmberIcon sx={{ color: "error.main" }} />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "500px" }}>
          <OnlyTable />
        </TableContainer>
      </Paper>
      {elementsOnPage < props.items.length ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <PaginationComponent
            count={countPage(props.items.length, elementsOnPage)}
            page={page}
            handleChange={(newPage: number) => {
              setPage(newPage);
            }}
          />
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
