import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import React from "react";
import TranscriptionsComponent from "../../transcriptions/TranscriptionsComponent";
import TranslatesComponent from "../../translates/TranslatesComponent";
import newLearningWord from "../../../models/newLearningWord";
import learningWordTranscriptionType from "../../../models/learningWordTranscriptionType";
import AudioMultipleComponent from "../../audioComponent/AudioMultipleComponent";
import learningWordSoundType from "../../../models/learningWordSoundType";
import {
  bgColorByWordType,
  colorByWordType,
  imageByWordType,
  titleByWordType,
} from "../../../helpers/typeWordHelper";

interface propsInterface {
  continueHandler: CallableFunction;
  newLearningWord: newLearningWord;
  learningWordTranscriptionTypes: learningWordTranscriptionType[];
  learningWordSoundTypes: learningWordSoundType[];
}

export default function NewLearningWordWordLayout(props: propsInterface) {
  return (
    <Paper
      elevation={24}
      sx={{
        maxWidth: 600,
        margin: "0 auto",
        backgroundColor: bgColorByWordType(
          props.newLearningWord.learningWord.type,
        ),
        backgroundImage:
          "url(" +
          imageByWordType(props.newLearningWord.learningWord.type) +
          ")",
        backgroundSize: "auto 200px",
        backgroundPosition: "bottom right",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          pt: 5,
        }}
      >
        <Box
          sx={{
            p: 1,
            fontSize: 38,
            color: colorByWordType(props.newLearningWord.learningWord.type),
            textTransform: "uppercase",
          }}
        >
          <AudioMultipleComponent
            learningWordSounds={props.newLearningWord.sounds}
            learningWordSoundTypes={props.learningWordSoundTypes}
          />
          {props.newLearningWord.learningWord.word}
        </Box>
        <Box
          sx={{
            p: 1,
            fontSize: 16,
            color: "#fff",
            backgroundColor: colorByWordType(
              props.newLearningWord.learningWord.type,
            ),
            borderRadius: 1,
          }}
        >
          {titleByWordType(props.newLearningWord.learningWord.type)}
        </Box>
      </Box>
      <Box sx={{ p: 2, mb: 2 }}>
        <Box
          sx={{
            fontSize: 20,
            fontStyle: "italic",
          }}
        >
          <TranscriptionsComponent
            transcriptions={props.newLearningWord.transcriptions}
            learningWordTranscriptionTypes={
              props.learningWordTranscriptionTypes
            }
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Divider></Divider>
        </Box>
        <Box
          sx={{
            textTransform: "uppercase",
            fontSize: 18,
            mt: 2,
            mb: 6,
          }}
        >
          <TranslatesComponent
            translates={props.newLearningWord.translates ?? []}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={() => {
              props.continueHandler();
            }}
          >
            {"Продолжить"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
