import React, { useEffect } from "react";
import { vkAuthService } from "../../../services/vkAuthService/vkAuthService";
import { authSocial, authSocialData } from "../../../api/authSocial";
import { useSelector } from "react-redux";
import { storeInterface } from "../../../redux/store";
import userTokenApi from "../../../api/userToken/userTokenApi";
import Loader from "../../loader/Loader";
import appSelectors from "../../../redux/app/appSelectors";
import { LoadingButton } from "@mui/lab";
import { setTokenLoadingFalseDispatch } from "../../../redux/app/appDispatcher";

export default function VkAuthButton() {
  const temporaryToken = useSelector(
    (state: storeInterface) => state.app.temporaryToken,
  );
  const temporaryTokenLoading = useSelector(
    (state: storeInterface) => state.app.temporaryTokenLoading,
  );
  const tokenLoading = useSelector(appSelectors.tokenLoading);
  const tokenComplete = useSelector(appSelectors.tokenComplete);

  useEffect(() => {
    if (temporaryToken.length === 0) {
      userTokenApi();
    }

    return () => {
      setTokenLoadingFalseDispatch();
    };
  }, []);

  useEffect(() => {
    if (tokenComplete === false) {
      setTimeout(() => {
        checkAuth();
      }, 2000);
    }
  }, [tokenComplete]);

  function afterHandle() {
    checkAuth();
  }

  function checkAuth() {
    const data = authSocialData(temporaryToken);
    authSocial(data);
  }

  return (
    <>
      {temporaryTokenLoading === false ? (
        <LoadingButton
          loading={tokenLoading}
          variant="contained"
          onClick={() => {
            vkAuthService(afterHandle, temporaryToken);
          }}
          sx={{ width: "100%" }}
        >
          {"Через VK"}
        </LoadingButton>
      ) : (
        <Loader />
      )}
    </>
  );
}
